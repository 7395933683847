.rating-panel {
  min-width: 780px;

  &__head {
    display: flex;
    padding: 15px 10px 15px 15px;

    > div {
      padding: 0 10px;
    }
  }

  &__body {
    display: flex;
    margin-bottom: 20px;
    padding: 10px 10px 10px 15px;
    border-radius: 3px;
    border: solid 1px $gray-lighter;
    background-color: $white;

    @include media-breakpoint-up(md) {
      box-shadow: 0 11px 21px 0 $gray-lighter;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > div {
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      + div {
        border-left: 1px solid $gray-lighter;
      }
    }

    .indicators {
      &__percent {
        margin-bottom: 0;
      }
    }
  }
}
