.form-group-checkboxes {
  padding: 18px 10px 20px 20px;
  border-radius: 3px;
  border: solid 1px $gray-lighter;

  &.disabled {
    background-color: #e9ecef;
  }

  &__container {
    overflow-y: scroll;
    max-height: 300px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: #e9ecef;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: $white;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: $gray-lighter;
    }
  }
}

.custom-control {

  input {
    width: 15px;
    height: 15px;
    border-radius: 4.5px;
    border: solid 1px $gray;
    background-color: $white;
  }
}

.custom-control-input {

  &:checked {
    ~ .custom-control-label {
      color: $gray-dark;

      &:before {
        border-color: $blue;
        background-color: $blue;
      }
    }
  }

  &:focus {
    ~ .custom-control-label {
      &:before {
        box-shadow: none;
      }
    }
  }
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $gray;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url(../../../img/check.svg);
  background-size: 68%;
  background-position: center;
}

.custom-control-label {
  letter-spacing: 0.7px;
  color: $gray;
}

.custom-control-input {
  &[type='radio'] {
    ~ .custom-control-label {
      &:before {
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        border-width: 3px;
        background-color: #fff;
      }
    }
  }
}

.custom-radio .custom-control-input[type='radio']:checked {

  ~ .custom-control-label {
    color: $orange;

    &::before {
      border-color: $orange;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: -18px;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-image: none;
      background-color: $orange;;
    }
  }
}
