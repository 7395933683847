.indicators-togglers {
  min-height: 31px;

  .nav {
    align-items: center;

    @include media-breakpoint-up(lg) {
      justify-content: center;
    }


    li {
      margin-right: 10px;

      @include media-breakpoint-up(lg) {
        margin: 0 5px;
      }
    }
  }

  a {
    display: inline-block;
    min-width: 75px;
    padding: 0.5px 12px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.9px;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    text-transform: uppercase;
    color: $gray;
    border-radius: 3px;
    box-shadow: 0 0 20px 0 rgba(178, 196, 213, 0.2);
    border: solid 1px $gray-lighter;
    background-color: $white;
    cursor: pointer;

    &.active {
      background-color: #eff4f9;
      color: $gray-dark;
      box-shadow: none;
    }

    &:hover {
      text-decoration: none;
    }
  }
}
