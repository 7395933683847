.page-datepicker {
  width: 100%;

  &__input-container {
    //width: 190px;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 730px) {
      display: block;
    }

    @include media-breakpoint-up(md) {
      width: 140px;
      margin-bottom: 0;
      margin-right: 8px;

      &:last-child {
        margin-right: 10px;
      }
    }
  }

  &__input {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .rdt {
    position: relative;

    input {
      padding: 10.5px 13px 10.5px;
      font-size: $font-size-base;
      letter-spacing: 0.7px;
      text-align: center;
      color: $gray-dark;
      border-radius: 3px;
      border: 1px solid $gray-lighter;
      background-color: $white;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      @include media-breakpoint-up(md) {
        padding-top: 6px;
        padding-bottom: 5px;
      }
    }
  }

  .rdtPicker {
    display: none;
    position: absolute;
    min-width: 266px;
    padding: 20px 15px 25px;
    top: 100%;
    left: 0;
    margin-top: 10px;
    z-index: 1 !important;
    background: #fff;
    box-shadow: 0 30px 40px 0 #b2c4d5;
    border: 1px solid #e3e7ee;
    border-radius: 3px;
    color: #485766;
    font-size: 1rem;

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 1px;
    }

    &:before {
      left: 69px;
      border: 9px solid transparent;
      border-top: none;
      border-bottom-color: #e3e7ee;
      top: -9px;
    }

    &:after {
      left: 70px;
      border: 8px solid transparent;
      border-top: none;
      border-bottom-color: #fff;
      top: -8px;
    }
  }

  .rdtOpen .rdtPicker {
    display: block;
  }

  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }

  .rdtPicker .rdtTimeToggle {
    text-align: center;
  }

  .rdtPicker table {
    width: 100%;
    margin: 0;
    border: none;
  }

  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    height: 30px;
    width: 30px;
  }

  .rdtPicker td {
    cursor: pointer;
  }

  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
  }

  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #999999;
  }

  .rdtPicker td.rdtToday {
    position: relative;
  }

  .rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid $blue;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }

  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: $blue;
    color: #fff;
    text-shadow: none;
  }

  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
  }

  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }

  .rdtPicker td span.rdtOld {
    color: #999999;
  }

  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }

  .rdtPicker th {
    border-bottom: none;
  }

  .rdtPicker .dow {
    height: 50px;
    font-size: $font-size-base;
    font-weight: normal;
    text-transform: capitalize;
    letter-spacing: 0.7px;
    color: #b2c4d5;
    border-bottom: none;
    cursor: default;
  }

  .rdtPicker th.rdtSwitch {
    width: 100px;
    color: $blue;
    font-weight: normal;
    text-transform: capitalize;
  }

  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }

  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }

  .rdtPicker thead tr:first-child th {
    cursor: pointer;
  }

  .rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
  }

  .rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
  }

  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .rdtPicker button:hover {
    background-color: #eee;
  }

  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }

  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }

  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee;
  }

  .rdtCounters {
    display: inline-block;
  }

  .rdtCounters > div {
    float: left;
  }

  .rdtCounter {
    height: 100px;
  }

  .rdtCounter {
    width: 40px;
  }

  .rdtCounterSeparator {
    line-height: 100px;
  }

  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  .rdtCounter .rdtBtn:hover {
    background: #eee;
  }

  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }

  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
  }

  .rdtMilli input {
  }

  .rdtTime td {
    cursor: default;
  }
}
