.polls-panel {
  min-width: 780px;

  &__head {
    display: flex;
    padding: 8px 0;
  }

  &__body {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 3px;
    margin-bottom: 20px;
    font-size: 0.9375rem;
    border: solid 1px $gray-lighter;
    background-color: $white;

    @include media-breakpoint-up(md) {
      box-shadow: 0 11px 21px 0 $gray-lighter;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.has-copy-link {
        flex-direction: row;
        align-items: center;

        .copy-link {
          flex: 0 0 30px;
        }
      }

      + div {
        border-left: 1px solid $gray-lighter;
      }
    }
  }

  .col-4 {
    padding: 8px 10px 8px 40px;
  }

  .col-5 {
    padding: 8px 10px 8px 40px;
  }

  .col-2 {
    padding: 8px 25px 8px 25px;

    .page-switch {
      margin: 0 auto;
    }
  }

  .col-1 {
    padding: 8px 20px 8px 20px
  }
}
