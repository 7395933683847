.page {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: scrollbar;
  -ms-text-size-adjust: 100%;
  min-width: 320px;
  min-height: 100%;

  &__inner {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &__content {
    flex-grow: 1;
    padding-top: $header-height-mobile;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  &__main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $gray-lightest;
    height: 100%;

    @media all and (-ms-high-contrast:none) {
      min-height: calc(100vh - #{$header-height-mobile});
    }
  }

  &__footer-wrapper {
    z-index: 1;
    flex-shrink: 0;
  }
}
