.btn-filter {
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background: $white;
  border: solid 1px $gray-lighter;
  box-shadow: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  .svg-sprite-icon {
    width: 24px;
    height: 24px;
    fill: $gray-dark;
  }

  @include media-breakpoint-up(md) {
    width: 30px;
    height: 30px;

    .svg-sprite-icon {
      width: 20px;
      height: 20px;
    }
  }

  &[aria-expanded="true"] {
    .svg-sprite-icon {
      fill: $blue;
    }
  }
}
