.main-nav {

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    &:before {
      display: none;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -20px;
      border-left: 4px solid $blue;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      height: 40px;
      width: 0;
      border-radius: 3px;
    }

    .svg-sprite-icon {
      width: 40px;
      height: 40px;
      fill: $gray-dark;
    }

    &.is-active {
      color: $blue;
      box-shadow: 0 11px 21px 0 $gray-lighter;
      background-color: $gray-lightest;
      outline: solid 1px $gray-lighter;

      .svg-sprite-icon {
        fill: $blue;
      }

      &:before {
        display: block;
      }
    }

    &:hover, &:active {
      box-shadow: 0 11px 21px 0 $gray-lighter;
      background-color: $gray-lightest;
      outline: solid 1px $gray-lighter;
      color: $blue;
      text-decoration: none;

      &:before {
        display: block;
      }

      .svg-sprite-icon {
        fill: $blue;
      }
    }

    &:focus {
      text-decoration: none;
    }
  }

  &__item {
    position: relative;
  }

  &__new {
    position: absolute;
    top: 0px; 
    right: 5px;
    color: $orange-dark;
    font-size: 14px;
    font-weight: bold;
    z-index: 10;

    @media (max-width: 991px) {
      top: 10%; 
      right: 10%;
    }
  }
}
