.users-panel {
  min-width: 780px;

  &__head {
    display: flex;
    padding: 8px 0;
    font-size: 0.875rem;
    color: $gray;
  }


  &__body-name {

    span {
      margin-left: 10px;

      .icon-alert {
        position: relative;
        top: -2px;
        width: 15px;
        height: 15px;
        stroke: #dc3545;
        fill: none;
      }
    }
  }

  &__body {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 3px;
    margin-bottom: 20px;
    font-size: 0.9375rem;
    border: solid 1px $gray-lighter;
    background-color: $white;

    @include media-breakpoint-up(md) {
      box-shadow: 0 11px 21px 0 $gray-lighter;
    }

    > div {
      display: flex;
      align-items: center;

      + div {
        border-left: 1px solid $gray-lighter;
      }
    }
  }

  .col-4 {
    padding: 8px 10px 8px 20px;

    @include media-breakpoint-up(lg) {
      padding-left: 40px;
    }
  }

  .col-3 {
    padding: 8px 5px 8px 20px;

    @include media-breakpoint-up(lg) {
      padding-left: 55px;
    }
  }

  .col-2 {
    padding: 8px 5px 8px 15px;

    @include media-breakpoint-up(lg) {
      padding-left: 55px;
    }
  }

  .col-1 {
    padding: 8px 20px 8px 20px;
  }

  .user-status {
    width: 40px;
    height: 30px;
    font-size: 0.875rem;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    border-radius: 3px;
    box-shadow: 0 0 20px 0 rgba(178, 196, 213, 0.2);
    border: solid 1px $gray-lighter;
    background-color: $gray;

    &.is-active {
      background-color: $blue;
    }
  }
}
