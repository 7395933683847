.statistics-start {
  text-align: center;

  &__container {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  &__title {
    margin: 40px 0;
    font-size: 16px;
    letter-spacing: 0.8px;
    text-align: center;
  }

  &__list {
    margin-bottom: 40px;
  }

  &__text {
    letter-spacing: 0.7px;
    color: $gray;
  }
}
