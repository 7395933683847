.form-control {

  &:focus {
    border-color: $blue;
    box-shadow: 0 0 0 1.5px  rgba(116, 173, 230, 0.25);

    ~ svg {
      fill: $orange-dark;
    }
  }

  &-email,
  &-password {
    padding-left: 50px;
  }

  &.no-lock-icon {
    padding-left: 0.75rem;

    ~ .icon-lock {
      display: none;
    }
  }

  &-wrapper {
    position: relative;

    .icon-email {
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 20px;
      width: 24px;
      height: 20px;
      fill: #a8b3c2;
    }

    .icon-lock {
      position: absolute;
      top: 50%;
      margin-top: -12px;
      left: 23px;
      width: 18px;
      height: 24px;
      fill: #a8b3c2;
    }

    .toggle-password {
      position: absolute;
      top: 50%;
      margin-top: -12px;
      right: 15px;
      width: 18px;
      height: 24px;
      cursor: pointer;

      .icon-eye {
        width: 18px;
        height: 24px;
        fill: #a8b3c2;
      }

      &-visible {
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          height: 1px;
          background: $gray-dark;
          transform: translateY(-50%) rotate(-45deg);
        }

        .icon-eye {
          fill: $gray-dark;
        }
      }
    }
  }

  &.is-invalid, &.is-valid {
    ~ .toggle-password {
      right: 30px;
    }
  }

  &__reactPhone {
    padding: 0;
  }
}
