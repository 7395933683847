.btn {
  width: 180px;
  font-size: 17px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 40px;
  color: $white;
  border: none;
  box-shadow: 0 11px 21px 0 $gray-lighter;

  &-confirm {
    position: relative;
    background-image: linear-gradient(to right, $orange, $orange-light);

    .icon-btn {
      background-color: $orange;

      .icon-confirm {
        width: 17px;
        height: 12px;
        fill: $white;
      }

      .icon-plus {
        width: 11px;
        height: 11px;
        fill: $white;
      }
    }

    &:hover {
      color: $white;
      box-shadow: 0 11px 21px 0 #ebaeaa;
      background-image: linear-gradient(
        to right,
        $orange-darken,
        $orange-lighten
      );

      .icon-btn {
        background-color: $orange-darken;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  &-demo {
    position: relative;
    background-image: linear-gradient(to right, $gray-lighter, $white);
    color: $orange;

    &:hover {
      color: $orange;
      box-shadow: 0 11px 21px 0 $gray-lighten;
      background-image: linear-gradient(to right, $gray-lighten, $gray-lighter);
    }

    &:focus {
      box-shadow: none;
    }
  }

  &-cancel {
    background-image: linear-gradient(to right, $gray-light, $gray-lighten);

    .icon-btn {
      background-color: #b3c5d5;

      .icon-refresh {
        width: 19px;
        height: 23px;
        fill: $white;
      }

      .icon-close {
        width: 11px;
        height: 11px;
        fill: $white;
      }
    }

    &:hover {
      color: $white;
      box-shadow: 0 11px 21px 0 $gray-light;
      background-image: linear-gradient(to right, #9babbb, #bac9d6);

      .icon-btn {
        background-color: #9bacbb;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.has-icon {
    padding-right: 25px;
    position: relative;

    .icon-btn {
      position: absolute;
      top: 50%;
      right: 7px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }
}

.btn-panel-add {
  display: inline-block;
  font-size: 17px;
  padding: 7.5px 46px 7.5px 3%;
  border-radius: 40px;
  color: $white;
  border: none;
  box-shadow: 0 11px 21px 0 $gray-lighter;
  background-image: linear-gradient(to right, $orange, $orange-light);
  white-space: nowrap;

  span {
    position: relative;
  }

  .icon-btn {
    position: absolute;
    top: 50%;
    right: -39px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-left: 10px;
    border-radius: 50%;
    background-color: $orange;

    .icon-plus {
      width: 14px;
      height: 14px;
      fill: $white;
    }
  }

  &:hover,
  &:active {
    color: $white;
    text-decoration: none;
    box-shadow: 0 11px 21px 0 #ebaeaa;
    background-image: linear-gradient(
      to right,
      $orange-darken,
      $orange-lighten
    );

    .icon-btn {
      background-color: $orange-darken;
    }
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-add {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 8.5px 8px 8.5px 17px;
  text-align: center;
  color: $gray-dark;
  border-radius: 3px;
  border: solid 1px $gray-lighter;
  background-color: $white;

  @include media-breakpoint-up(md) {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }

  .svg-sprite-icon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    fill: $gray-light;
  }

  &:hover {
    color: $gray-dark;
    text-decoration: none;
    border-color: $gray-dark;

    .svg-sprite-icon {
      fill: $gray-dark;
    }
  }
}

.btn-edit {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  user-select: none;

  .icon-edit {
    width: 20px;
    height: 20px;
    fill: $gray-light;
  }

  &:hover,
  &:focus {
    .icon-edit {
      fill: $blue;
    }
  }
}

.btn-report {
  width: 70px;
  margin: 0 auto;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.9px;
  color: $green-dark;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border-radius: 3px;
  box-shadow: 0 0 20px 0 rgba(178, 196, 213, 0.2);
  border: solid 1px $gray-lighter;
  background-color: $green-lightest;

  &:hover,
  &:active {
    text-decoration: none;
    background-color: $green-lighter;
    color: $green-dark;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.65;
    filter: grayscale(100%);
  }
}

.btn-responses {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 3px;
  border: solid 1px $gray-lighter;
  background-color: $white;

  @include media-breakpoint-up(lg) {
    width: 60px;
  }

  &:hover {
    background-color: #eaf3fb;

    svg {
      fill: $blue;
    }
  }

  &:active {
    background-color: $white;

    svg {
      fill: $gray-dark;
    }
  }

  &:focus {
    outline: none;
  }

  svg {
    width: 16px;
    height: 15px;
    fill: $gray;
  }
}

.btn-share {
  width: auto;

  svg {
    margin-right: 5px;
    width: 15px;
    height: auto;
  }
}

.btn-get-started {
  width: auto;
  margin-top: 2.5vh;
  font-size: 14px;
}
