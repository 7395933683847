.privacy-terms {
  height: 100%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 1rem;

  &__label {
    letter-spacing: 0.7px;
    color: $gray-darken;
    text-align: center;
  }
}
