.page-select {
  $block-name: &;
  position: relative;

  &__selected {
    position: relative;
    padding: 9.5px 35px 9.5px 13px;
    color: $gray-dark;
    background-color: $white;
    cursor: pointer;
    border-radius: 3px;
    border: solid 1px $gray-lighter;
    @include text-truncate();

    @include media-breakpoint-up(md) {
      padding: 4.5px 35px 4.5px 13px;
    }

    &:after {
      content: '';
      position: absolute;
      right: 14px;
      top: 50%;
      width: 8px;
      height: 8px;
      transition: transform 0.3s;
      border-bottom: 1px solid $blue;
      border-right: 1px solid $blue;
      transform: translateY(-70%) rotate(45deg);
    }
  }

  &__list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    margin: 0;
    padding: 0;
    list-style: none;
    border-radius: 0 0 3px 3px;

    li {
      padding: 8px 13px;
      color: $gray;
      border: solid 1px $gray-lighter;
      border-top: none;
      background-color: $white;
      cursor: pointer;
      @include text-truncate();

      &:last-child {
        border-radius: inherit;
      }

      &:hover {
        background-color: $blue;
        color: $white;
        border-color: $blue;
      }
    }
  }

  &.is-open {

    #{$block-name}__selected {
      border-radius: 3px 3px 0 0;
      &:after {
        transform: translateY(-30%) rotate(225deg);
      }
    }

    #{$block-name}__list {
      display: block;
      box-shadow: 0 30px 40px 0 $gray-light;
    }
  }
}
