.progress-chart {
  width: 95%;
  height: 20px;
  margin: 0 auto;
  background-color: $color-danger;
  font-size: 0;

  &__item {
    display: inline-block;
    height: inherit;

    &:nth-child(1) {
      width: 10%;
    }

    &:nth-child(2) {
      width: 30%;
      background: $gray;
    }

    &:nth-child(3) {
      width: 60%;
      background: $green-dark;
    }
  }
}
