.sortable-link {
  color: $gray;
  // cursor: pointer;

  .icon-sort {
    margin-left: 4px;
    width: 12px;
    height: 12px;
    fill: $gray;
  }

  &:hover, &:focus {
    text-decoration: none;
    // color: $gray-dark;

    .icon-sort {
      fill: $gray-dark;
    }
  }
}