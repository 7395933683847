.page-qr {
  $good: #b8d678;
  $bad: #d55242;
  $neutral: #f4a257;
  $bg: #f7fafc;
  $primary: #eaf3fa;
  $shadow-color: #c0c7d4;
  $text-color: #010101;
  $text-color-footer: #b2c4d5;

  %title {
    font-size: 25px;
    font-weight: 700;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Montserrat, sans-serif;
  background: #fff;

  .container {
    padding: 7.5vh 20px 4vh 20px;
    height: 100%;

    &.has-questions {
      padding-top: 5.7vh;
    }
  }

  &__logo {
    margin-bottom: 7.5vh;
    text-align: center;

    img {
      max-width: 180px;
    }
  }

  &__buttom-logo {
    a {
      margin-bottom: 2px;
    }
    img {
      margin-bottom: 3px;
      height: 18px;
    }
  }

  &__rate {
    max-width: 300px;
    margin: 0 auto;
  }

  &__rate-question {
    @extend %title;
    position: relative;
    padding: 50px 20px;
    letter-spacing: 1.15px;
    line-height: 30px;
    text-align: center;
    color: $text-color;
    border-radius: 10px;
    border: solid 7px $primary;
    background: $primary;

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: -36px;
      left: -7px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 18px;
      border-color: $primary transparent transparent $primary;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      bottom: -18px;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 18px;
      border-color: $primary transparent transparent $primary;
    }
  }

  &__rate-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 12vh;

    .qr-btn {
      font-size: 0;
      display: block;
      width: 98px;
      height: 99px;
      background: transparent;
      background-image: url(../../../img/qr-sprite.png);
      background-repeat: no-repeat;
      border: none;

      &:active {
        transform: translateY(2px);
      }

      &:active,
      &:focus {
        border: none;
        outline: none;
      }

      &--bad {
        background-position: -5px -5px;
        &:active {
          background-position: -106px -5px;
        }
      }

      &--neutral {
        background-position: -409px -5px;
        &:active {
          background-position: -510px -5px;
        }
      }

      &--good {
        background-position: -207px -5px;
        &:active {
          background-position: -308px -5px;
        }
      }
    }
  }

  &__footer {
    padding-bottom: 4.7vh;

    p {
      margin: 0;
      text-align: center;
      color: $text-color-footer;

      a {
        font-size: 14px;
        text-decoration: underline;
        color: inherit;

        &:hover,
        &:focus,
        &:active {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }

  &__question-name {
    @extend %title;
    margin-bottom: 3.8vh;
    color: #000000;
  }

  &__sended-img {
    display: block;
    margin: 0 auto 5vh;
  }

  &__few-links-sended-img {
    display: block;
    margin: 0 auto 6vh;
  }

  &__sended-title {
    @extend %title;
    margin-bottom: 3.1vh;
    letter-spacing: 0.58px;
    color: #000000;
  }

  &__sended-subtitle {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #000000;
    max-width: 450px;
  }

  &__feedback-text {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #000000;
    max-width: 450px;
  }

  &__feedback-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5vh 20px 7.5vh 20px;
    text-align: center;
  }

  &__sended-subtitle-container {
    display: flex;
    justify-content: center;
  }

  &__few-links-subtitle {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #000000;
  }

  &__few-links-link {
    display: block;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.4px;
    text-decoration: underline;
  }

  .qr-question-form {
    &__questions {
      margin-bottom: 4.7vh;
    }

    &__custom-answer {
      margin-bottom: 4.7vh;
    }

    &__controls {
      text-align: center;
    }

    &__btn {
      width: 280px;
      padding-top: 13px;
      padding-bottom: 13px;
      border-radius: 30px;
      box-shadow: 0 11px 20px 0 #c0c7d4;
      background: linear-gradient(to right, #ff790e, #f8b751);
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;

      &:hover,
      &:focus,
      &:active {
        box-shadow: 0 11px 21px 0 #ebaeaa;
        background-image: linear-gradient(to right, #e34905, #e89f36);
      }
    }

    &__call-me-block {
      flex-direction: column;
    }
  }

  .qr-question-checkbox {
    color: $text-color;
    font-size: 18px;
    border-top: 1px solid #eaf3fb;
    border-bottom: 1px solid #eaf3fb;

    + .qr-question-checkbox {
      border-top: none;
    }

    &__input-wrap {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &__name {
      display: inline-block;
      position: relative;
      margin: 0;
      padding-left: 42px;
      font-weight: 500;
      line-height: 1.7;
    }

    &__input {
      position: absolute;
      top: 0.7em;
      left: 0;
      margin: 0;
      padding: 0;
      transform: translateY(-50%);
      opacity: 0;
    }

    input:checked ~ .qr-question-checkbox__name-text {
      &:before {
        background-color: #74ade6;
        border-color: #74ade6;
      }

      &:after {
        opacity: 1;
      }
    }

    &__name-text {
      display: inline-block;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        border: solid 2px #d3dae5;
        background-color: #ffffff;
        border-radius: 50%;
      }

      &:after {
        content: '';
        position: absolute;
        top: 6px;
        left: 6px;
        width: 17px;
        height: 11px;
        opacity: 0;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(-45deg);
        transition: opacity 0.3s;
      }
    }
  }

  .qr-question-custom-answer {
    width: 100%;
    display: block;
    min-height: 40px;
    padding: 14px 16px;
    border-radius: 3px;
    font-size: 18px;
    font-weight: 500;
    color: #8b9eb0;
    background-color: #e9edf2;
    border: none;

    &.has-feedback {
      background: #fff;
      outline: none;
      box-shadow: 0 0 0 2px #74ade6;
      color: #000000;
    }
  }

  .qr-question-call-me {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-bottom: 20px;

    &__input-block {
      margin-bottom: 10px;
    }

    &__confidential-terms {
      p {
        color: $text-color-footer;

        a {
          font-size: 14px;
          text-decoration: underline;
          color: inherit;

          &:hover,
          &:focus,
          &:active {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }

    .required-label {
      color: #dc3545;
      margin-right: 2px;
    }
  }
}
