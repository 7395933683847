.user-form {
  border-radius: 3px;
  border: solid 1px $gray-lighter;
  background-color: $white;

  &__conatiner {
    padding: 15px 20px 30px 20px;

    @include media-breakpoint-up(md) {
      padding: 25px 48% 40px 30px;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 58%;
    }
  }

  &__conatiner--profile {
    padding-left: 20px;

    @include media-breakpoint-up(md) {
      padding-right: 30px;
    }
  }

  &__info {

    @include media-breakpoint-up(md) {
      padding-right: 48%;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 58%;
    }
  }

  &__change-password {

    .form-group {

      @include media-breakpoint-up(md) {
        padding-right: 55%;
      }

      @include media-breakpoint-up(lg) {
        padding-right: 65%;
      }

      p {
        color: $gray-lighten;
      }
    }
  }

  &__change-password-title {
    margin-bottom: 25px;
    padding-bottom: 5px;
    color: $gray-dark;
    border-bottom: 1px solid $gray-lighter;
  }
}
