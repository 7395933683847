.backstage {
  min-height: 100vh;
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    background-color: #ededed;
    padding: 40px 20px 20px 20px;
    border-radius: 8px;
    flex-direction: column;
    color: #000000;
    min-width: 20%;
  }

  &__header {
    font-size: 36px;
    font-weight: bold;
    border-bottom: 3px solid black;
    width: 100%;
    text-align: center;
    font-family: 'Roboto Condensed', serif;
  }

  &__text {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    border-bottom: 3px solid black;
    margin-bottom: 10px;

    p {
      margin-bottom: 10px;
      line-height: 16px;
    }
  }

  &__buttons-containers {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    a {
      text-decoration: none;
      outline: none;
    }
  }

  &__button {
    display: block;
    background-color: #000000;
    color: #f1f1f1;
    width: 100%;
    border-radius: 30px;
    margin-bottom: 5px;
    font-size: 12px;
    padding: 10px 0;
    cursor: pointer;

    &:hover {
      background-color: #4d4d4d;
      color: #ffffff;
      transition: 0.3s;
    }
  }
}
