.menu {
  position: absolute;
  top: $header-height-mobile;
  height: 0;
  right: -10px;

  @include media-breakpoint-up(xl) {
    top: 70px;
    right: 10px;
    height: auto;
    border: solid 1px $gray-lighter;
    box-shadow: 0 11px 21px 0 $gray-lighter;
    border-radius: 6px;

    &:before {
      content: '';
      position: absolute;
      top: -7px;
      right: 18px;
      width: 15px;
      height: 15px;
      background: #000;
      transform: rotate(-45deg);
      border-top: solid 1px $gray-lighter;
      border-right: solid 1px $gray-lighter;
      background: $white;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    right: 0;
    z-index: 2;
    width: 300px;
    height: calc(100vh - #{$header-height-mobile});
    overflow-y: auto;
    background-color: $white;

    @include media-breakpoint-up(xl) {
      right: 0;
      height: 100%;
      overflow-y: visible;
      border-radius: inherit;
    }
  }

  &__list-lang {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    right: 0;
    z-index: 2;
    width: 100%;
    overflow-y: auto;
    background-color: $white;

    @include media-breakpoint-up(xl) {
      right: 0;
      height: 100%;
      overflow-y: visible;
      border-radius: inherit;
    }
  }

  &__item {
    position: relative;
    font-size: 16px;
    letter-spacing: 0.8px;
    text-align: left;
    color: $gray-dark;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      height: 1px;
      background-color: $menu-active-color;
    }

    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    @include media-breakpoint-up(xl) {
      &:last-child {

        &:after {
          display: none;
        }

        a {
          border-radius: 0 0 6px 6px;
        }
      }
    }
  }

  &__item--user-data {
    padding: 20px 10px 20px 28px;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 20px 20px 20px 30px;
    color: $gray-dark;
    background: $white;

    &:before {
      display: none;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -20px;
      border-left: 4px solid $blue;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      height: 40px;
      width: 0;
      border-radius: 3px;
    }

    .svg-sprite-icon {
      flex: 0 0 40px;
      width: 40px;
      height: 40px;
      margin-right: 30px;
      fill: $gray-dark;
    }

    &.is-active {
      position: relative;
      background-color: $menu-active-color;
      color: $blue;

      .svg-sprite-icon {
        fill: $blue;
      }

      &:before {
        display: block;
      }
    }

    &:hover, &:active {
      background-color: $menu-active-color;
      color: $blue;
      text-decoration: none;

      &:before {
        display: block;
      }

      .svg-sprite-icon {
        fill: $blue;
      }
    }

    &:focus {
      text-decoration: none;
    }
  }

  &__backdrop {
    position: fixed;
    z-index: 1;
    height: auto;
    width: auto;
    top: $header-height-mobile;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $gray-dark;
    opacity: 0.95;
    transition: opacity 0.3s;

    @include media-breakpoint-up(xl) {
      display: none;
      width: 0;
      height: 0;
      opacity: 0;
    }
  }
}
