.custom-switch {
  display: inline-block;
  padding-left: 0;
  padding-right: calc(#{$custom-switch-width} + 5px);

  .custom-control-label {

    &:before {
      left: initial;
      right: calc(-#{$custom-switch-width} - 5px);
      border: none;
      background-image: linear-gradient(to right, #c8d7e5, #b2c4d5);
    }

    &:after {
      left: initial;
      right: calc(-2.05rem + 2px);
      top: 50%;
      margin-top: calc(-1.25rem/2);
      background-color: $gray-dark;
    }
  }

  .custom-control-input {

    &:focus {

      ~ .custom-control-label:before {
        box-shadow: none;
      }
    }

    &:checked ~ .custom-control-label:before {
      background-image: linear-gradient(to right, #5bceae, #92e1cb);
    }

    &:checked ~ .custom-control-label:after {
      transform: translateX(2.35rem);
    }
  }
}
