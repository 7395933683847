.form-group {

  > label {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.7px;
  }

  .required-label {
    color: #dc3545;
    margin-right: 2px;
  }

  &.has-page-switch {
    display: flex;

    .page-switch-label {
      line-height: 30px;
      margin-right: 10px;

      @include media-breakpoint-up(md) {
        margin-right: 30px;
      }
    }
  }

  &.has-submit-btn {
    padding-top: 5px;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    .btn + .btn {
      margin-top: 20px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 40px;
      }
    }
  }

  .support-help-text {
    margin-top: 10px;
    text-align: right;
  }
}
