.rating {

  &__top {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-lighter;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      padding-top: 30px;
    }
  }

  &__title {
    display: none;
    letter-spacing: 0.7px;
    color: $gray;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__filters {
    position: relative;

    @include media-breakpoint-up(md) {
      display: flex;
    }

    .page-select {
      min-width: 270px;
      max-width: 270px;
      margin-bottom: 10px;
      text-align: center;

      &:nth-child(2) {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(md) {
        min-width: 160px;
        max-width: 230px;
        margin-bottom: 0;
        margin-right: 20px;
        text-align: left;

        &:nth-child(2) {
          margin-right: 40px;
        }
      }
    }

    .icon-filter-rating {
      position: absolute;
      top: 10px;
      left: 280px;
      width: 20px;
      height: 20px;
      fill: $blue;

      @include media-breakpoint-up(md) {
        top: 7px;
        right: 5px;
        left: initial;
      }
    }
  }

  &__panel {
    overflow-x: scroll;

    @include media-breakpoint-up(xl) {
      overflow-x: initial;
    }
  }
}
