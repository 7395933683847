.user-data {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &__info {
    padding-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__info-name {
    display: block;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__info-mail {
    display: block;
    line-height: 18px;
    color: $gray-light;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
