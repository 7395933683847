.modal-responses {

  .modal {
    &-dialog {

      @include media-breakpoint-up(md) {
        max-width: 98%;
      }

      @include media-breakpoint-up(lg) {
        max-width: 1000px;
      }
    }

    &-content {
      border: none;
    }

    &-header {
      padding: 30px 20px 15px;
      border-color: $border-color;

      @include media-breakpoint-up(md) {
        padding: 30px;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 40px;
        padding-right: 40px;
      }

      h5 {
        margin: 0;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1.2px;
        color: $blue;
      }
    }

    &-body {
     padding: 20px 20px 10px 20px;

      @include media-breakpoint-up(md) {
        padding-left: 30px;
        padding-right: 30px;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    &-footer {
      justify-content: flex-start;
      padding: 30px 20px;
      border-color: $border-color;

      @include media-breakpoint-up(md) {
        padding: 30px;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }

  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: #e3e7ee;
    border-radius: 50%;

    &:focus {
      outline: none;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 18px;
        height: 18px;
        transform: rotate(45deg);
      }
    }
  }
}
