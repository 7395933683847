.login-form {
  border-radius: 3px;
  border: solid 1px $gray-lighter;
  background-color: $white;

  &__container {
    padding: 30px 20px;

    @include media-breakpoint-up(md) {
      padding: 50px 55px;
    }
  }

  &__container--demo-container {
    border-radius: 10px;
    background-color: $green-lighten;
    padding: 20px 20px;

    @include media-breakpoint-up(md) {
      padding: 20px 55px;
    }
  }

  &__title {
    position: relative;
    margin-bottom: 25px;
    padding-bottom: 40px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.6px;
    text-align: center;
    color: $black;
    border-bottom: solid 1px $gray-lighter;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      padding-bottom: 40px;
    }

    &:before {
      display: none;
      content: '';
      position: absolute;
      top: -8px;
      left: 0;
      width: 41px;
      height: 41px;
      background: url(../../../img/logo-sm.png) no-repeat center;

      @media (min-width: 350px) {
        display: block;
      }
    }
  }

  &__title--no-icon {
    &:before {
      display: none;
    }
  }

  &__help-text {
    font-size: 16px;
    letter-spacing: 0.4px;
    color: $gray;
  }

  &__demo {
    font-size: 16px;
    letter-spacing: 0.6px;
    text-align: center;
    color: $black;
  }

  .form-group {

    label {
      font-size: 16px;
      font-weight: normal;
      color: $black;
    }

    .form-check-label {
      font-size: 14px;
      letter-spacing: 0.35px;
      color: #92a8ba;

    }

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .btn {
      font-size: 22px;
    }

    .error {
      text-align: right;
    }
  }
}
