.page-login {
  background-color: $gray-lightest;
  min-height: 100vh;

  .container {
    padding-top: 60px;
    padding-bottom: 60px;

    @include media-breakpoint-up(lg) {
      padding-top: 160px;
    }
  }

  &__form {
    max-width: 470px;
    margin: 0 auto;
  }
}
