.modal-help {

  .modal-content {
    border-radius: 5px;
    box-shadow: 0 11px 21px 0 #e3e7ee;
    border: solid 1px #e3e7ee;
    background-color: #ffffff;
  }

  .modal-header {
    padding: 30px 15px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;

    @include media-breakpoint-up(md) {
      padding: 30px 30px 0;
    }
  }

  .modal-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.9px;
    color: #485766;
  }

  .modal-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
    background-color: #cfece7;
    border-radius: 50%;

    svg {
      width: 40px;
      height: 40px;
      fill: #33a28e;
    }
  }

  .modal-body {
    padding: 10px 15px 15px;
    justify-content: center;
    text-align: center;

    @include media-breakpoint-up(md) {
      padding: 10px 30px 15px;
    }
  }

  .form-group {
    margin-bottom: 0;

    label {
      margin-bottom: 30px;
      font-size: 14px;
      letter-spacing: 0.7px;
      text-align: center;
      font-weight: normal;
      color: #485766;
    }

    textarea {
      margin: 0;
      resize: none;
    }
  }

  .modal-footer {
    padding-top: 15px;
    padding-bottom: 30px;
    border: none;
    justify-content: center;

    .btn {
      width: 130px;
      margin: 10px;
      border-radius: 3px;
      background-image: linear-gradient(to right, #b2c4d5, #c8d7e5);
      box-shadow: none;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        margin-top: 0;
      }

      &-modal-cancel {

        &:hover {
          box-shadow: 0 11px 21px 0 #b2c4d5;
          background-image: linear-gradient(to right, #9babbb, #bac9d6);
        }
      }

      &-modal-confirm {
        background-image: linear-gradient(to right, #ff790e, #f8b751);
        box-shadow: none;

        &:hover {
          box-shadow: 0 11px 21px 0 #b2c4d5;
          background-image: linear-gradient(to right, #e34905, #e89f36);
        }
      }
      
      &:hover {
        color: #fff;
      }
    }
  }

  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: #e3e7ee;
    border-radius: 50%;

    &:focus {
      outline: none;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 18px;
        height: 18px;
        transform: rotate(45deg);
      }
    }
  }

  .error {
    text-align: left;
  }
}
