.login {
  display: flex;
  align-items: center;

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__btn {
    background: transparent;
    border: none;

    &:hover, &:active {
      .icon-menu {
        fill: $gray;
      }
    }

    @include hover-focus-active() {
      outline: none;
    }
  }

  &__menu {
    margin-right: -10px;
  }
}
