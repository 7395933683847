.react-multi-email-wrap {
  .react-multi-email {
    margin: 0;
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    padding: 0.375rem 0.75rem;
    background: #fff;
    border: 1px solid $border-color;
    color: rgba(0, 0, 0, 0.87);
    border-radius: $input-border-radius;
    -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
  }

  .react-multi-email > span[data-placeholder] {
    display: none;
    position: absolute;
    left: 0.5em;
    top: 0.4em;
    padding: 0.4em;
    line-height: 1.21428571em;
  }

  .react-multi-email.focused {
    border-color: #74ade6;
    box-shadow: 0 0 0 1.5px rgba(116, 173, 230, 0.25);
    background: #fff;
  }

  .react-multi-email.empty > span[data-placeholder] {
    display: inline;
    color: #ccc;
  }

  .react-multi-email.focused > span[data-placeholder] {
    display: none;
  }

  .react-multi-email > input {
    width: auto !important;
    outline: none !important;
    border: 0 none !important;
    display: inline-block !important;
    line-height: 1;
    vertical-align: baseline !important;
    padding: 0.4em 0.1em !important;
    color: $input-color;
  }

  .react-multi-email [data-tag] {
    line-height: 1;
    vertical-align: baseline;
    margin-right: 5px;
    background-image: none;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: $blue;
    color: $white;
    text-transform: none;
    border: 0 solid transparent;
    -webkit-transition: background 0.1s ease;
    -o-transition: background 0.1s ease;
    transition: background 0.1s ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
  }

  .react-multi-email [data-tag] [data-tag-item] {
    max-width: 100%;
    overflow: hidden;
  }

  .react-multi-email [data-tag]:first-child {
    margin-left: 0;
  }

  .react-multi-email [data-tag] [data-tag-handle] {
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  &.is-valid {
    .react-multi-email {
      border-color: #28a745;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  &.is-invalid {
    .react-multi-email {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
}
