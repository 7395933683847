.statistics {
  border-radius: 3px;
  box-shadow: 0 11px 21px 0 $gray-lighter;
  border: solid 1px $gray-lighter;
  background-color: $white;

  &__container {
    padding: 0 20px;

    @include media-breakpoint-up(lg) {
      padding: 20px 0;
    }

    .row {
      margin: 0;
    }
  }

  &__col {
    padding: 30px 0;
    border-bottom: solid 1px $gray-lighter;

    @include media-breakpoint-up(lg) {
      padding: 0 5px;
      border-right: solid 1px $gray-lighter;
      border-bottom: none;
    }

    &:last-child {
      border-bottom: none;

      @include media-breakpoint-up(lg) {
        border-right: none;
      }
    }

    .row {
      height: 100%;
    }
  }

  &__col-rate {
    padding: 0;
    margin-bottom: 35px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      border-right: solid 1px $gray-lighter;
    }

    &:last-child {
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        border-right: none;
      }
    }
  }

  &__col--topic {
    padding: 20px 0;

    @include media-breakpoint-up(lg) {
      padding: 0 20px;
    }

    @media (min-width: 1440px) {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  &__col--scores {
    @media (min-width: 1440px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  &__col--indicators {
    @media (min-width: 1440px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  &__col--rate {
    @media (min-width: 1440px) {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }
}

