@media (min-width: 1200px) {
  .modal-xl {
    max-width: 80%;
  }
}

// Tooltip Positioning Issue #457 https://github.com/popperjs/popper-core/issues/457
.tooltip {
  top: 0;
}

a:not([href]):not([class]):hover {
  cursor: pointer;
}

.error {
  margin-top: 5px;
  letter-spacing: 0.35px;
  color: #ff0000;
}

.close {
  font-weight: normal;
}

.support-help-text {
  margin: 0;
  letter-spacing: 0.7px;
  color: #a8b3c2;

  u {
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }

  svg {
    position: relative;
    margin-right: 5px;
    top: -2px;
    width: 16px;
    height: 16px;
    stroke: #a8b3c2;

    path {
     ~ circle {
        fill: #a8b3c2;
        stroke: none;
      }
    }
  }
}