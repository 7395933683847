// Override Bootstrap variables
$font-family-base: Fira Sans, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
$font-size-base: 0.875rem;
$headings-font-weight: 700;


// App base colors
$black: #000000;
$gray-darken: #454854;
$gray-dark: #485766;
$gray: #a8b3c2;
$gray-light: #b2c4d5;
$gray-lighten: #c8d7e5;
$gray-lighter: #e3e7ee;
$gray-lightest: #f7fafc;
$white: #ffffff;
$blue: #74ade6;
$red-dark: #cc001d;
$red: #cb4d5f;


$green-dark: #33a28e;
$green: #39a591;
$green-light: #5bceae;
$green-lighten: #92e1cb;
$green-lighter: #c5f3eb;
$green-lightest: #e3f5f2;


$orange-darken: #e34905;
$orange-dark: #ff7a0f;
$orange: #ff790e;
$orange-lighten: #e89f36;
$orange-light: #f8b751;


$color-success: $green;
$color-neutral: $gray;
$color-danger: $red;
$color-warning: $orange-light;
$body-color: $gray-dark;


$header-height-mobile: 80px;
$header-height-desktop: 80px;


$border-color: $gray-lighter;
$menu-active-color: #f2f5f8;


// Override React Datepicker variables
$datepicker__background-color: $white;
$datepicker__border-color: $gray-lighter;
$datepicker__font-family: $font-family-base;
$datepicker__font-size: 1rem;
$datepicker__text-color: $gray-dark;
$datepicker__header-color: $blue;
$datepicker__border-radius: 3px;
$datepicker__muted-color: $gray-lighter;
$datepicker__navigation-disabled-color: $gray-lighter;
$datepicker__day-margin: 0;
$datepicker__item-size: 2.32rem;

// Forms
$input-border-color: $gray-lighter;
$input-color: $body-color;
$input-font-size: 0.875rem;
$input-height: 2.5rem;
$input-border-radius: 3px;

// Modal
$modal-backdrop-bg: $gray-lighter;
$modal-backdrop-opacity: .7;

//Tooltip
$tooltip-bg: $gray-dark;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-opacity: 1;

// Change bs carousel transition duration
$carousel-transition: transform 1s ease, opacity 1s ease-out;