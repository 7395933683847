.page-switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 30px;
  margin-bottom: 0;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }

  input:checked + .page-switch__slider {
    background-image: linear-gradient(to left, $green-lighten, $green-light);
  }

  input:checked + .page-switch__slider:before {
    transform: translateX(36px);
    background-color: $white;
  }

  input:disabled + .page-switch__slider {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to left, $gray-light, $gray-lighten);
    transition: 0.4s;
    border-radius: 30px;

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 7px;
      top: 50%;
      margin-top: -10px;
      background-color: $gray-dark;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}
