.devices-panel  {
  min-width: 780px;

  &__head {
    display: flex;
    padding: 8px 0;
  }

  &__body {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 3px;
    margin-bottom: 20px;
    font-size: 0.9375rem;
    border: solid 1px $gray-lighter;
    background-color: $white;

    @include media-breakpoint-up(md) {
      box-shadow: 0 11px 21px 0 $gray-lighter;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      + div {
        border-left: 1px solid $gray-lighter;
      }
    }
  }

  &__body-polls {

    h5 {
      margin: 0;
      font-size: inherit;
      font-weight: normal;
    }

    p {
      margin: 0;
      color: $blue;
    }
  }

  &__body-period {

    span {
      color: $orange;
    }
  }

  &__body-status {
    .page-switch {
      margin: 0 auto;
    }
  }

  .col-2 {
    padding: 8px 10px 8px 10px;
  }

  .col-5 {
    padding: 8px 5px 8px 50px;
  }

  .col-1 {
    padding: 8px 20px 8px 20px
  }
}


