.page-pagination {
  display: flex;
  justify-content: center;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }

  .pagination {
    margin-bottom: 0;
    flex-wrap: wrap;
  }

  .page-item {
    margin: 5px;
  }

  .page-link {
    padding: 0.38rem 0.68rem;
    border-radius: 3px;
    border: solid 1px $gray-lighter;
    background-color: #eff4f9;
    color: $gray-darken;

    .svg-sprite-icon {
      width: 7px;
      height: 10px;
    }

    &:hover {
      background-color: $white;
    }

    &:focus {
      box-shadow: none;
    }

    &.is-active {
      background-color: $white;
      box-shadow: 0 11px 21px 0 $gray-lighter;
    }

    &.is-disabled {
      pointer-events: none;
    }
  }

  .page-link-prev {
    .svg-sprite-icon {
      transform: rotate(180deg);
    }
  }
}
