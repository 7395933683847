.select-input {
  border-radius: 3px;
  border: solid 1px #e3e7ee;
  position: relative;
  background: #ffffff url(../../../img/chevron.svg) 99%/100% no-repeat;
  background-size: 30px;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__item {
    position: relative;
    padding: 5px 20px 5px 10px;
    margin: 5px 3px;
    border-radius: 3px;
    background-color: $blue;
    color: $white;

    &:last-child {
      margin-right: 0;
    }
  }

  &__close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    right: 7px;
    font-size: 20px;
    cursor: pointer;
  }
}
