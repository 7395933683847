.modal-delete {

  .modal-content {
    border-radius: 5px;
    box-shadow: 0 11px 21px 0 #e3e7ee;
    border: solid 1px #e3e7ee;
    background-color: #ffffff;
  }

  .modal-header {
    padding-top: 30px;
    padding-bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .modal-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.9px;
    color: #485766;
  }

  .modal-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
    background-color: #fbccd3;
    border-radius: 50%;

    svg {
      width: 22px;
      height: 26px;
      fill: #cb4d5f;
    }
  }

  .modal-body {
    padding-top: 10px;
    padding-bottom: 15px;
    justify-content: center;
    text-align: center;
  }

  .modal-footer {
    padding-top: 15px;
    padding-bottom: 30px;
    border: none;
    justify-content: center;

    .btn {
      width: 130px;
      margin: 0 10px;
      border-radius: 3px;
      background-image: linear-gradient(to right, #b2c4d5, #c8d7e5);
      box-shadow: none;

      &-modal-cancel {

        &:hover {
          box-shadow: 0 11px 21px 0 #b2c4d5;
          background-image: linear-gradient(to right, #9babbb, #bac9d6);
        }
      }

      &-modal-confirm {
        background-image: linear-gradient(to right, #cb4d5f, #e46a7b);
        box-shadow: none;

        &:hover {
          box-shadow: 0 11px 21px 0 #b2c4d5;
          background-image: linear-gradient(to top, #c6233a, #f72a47);
        }
      }
      
      &:hover {
        color: #fff;
      }
    }
  }

  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: #e3e7ee;
    border-radius: 50%;

    &:focus {
      outline: none;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 18px;
        height: 18px;
        transform: rotate(45deg);
      }
    }
  }
}
