.content {
  &__header {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-top: 30px;
      padding-bottom: 20px;
      border-bottom: 1px solid $border-color;
    }
  }

  &__title {
    margin: 0 0 20px;
    padding-top: 5px;
    padding-bottom: 20px;
    font-size: 1.5rem;
    letter-spacing: 1.2px;
    color: $blue;
    border-bottom: 1px solid $border-color;

    @include media-breakpoint-up(md) {
      padding: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__header-options {
    display: flex;

    @media (max-width: 730px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__header-filters {
    display: flex;
    align-items: flex-start;
  }

  &__header-datepicker {
    margin-right: 10px;

    @media (max-width: 730px) {
      margin-bottom: 10px;
    }
  }

  &__header-stars {
    margin-right: 10px;
    
    @media (max-width: 730px) {
      display: flex;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  &__header-btn {
    margin-left: 20px;

    @include media-breakpoint-up(md) {
      margin-left: 10px;
    }
  }

  &__panels {
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 35px;
    }
  }

  &__panel {
    margin-bottom: 20px;
  }

  &__pagination--has-btn {
    @include clearfix();
    text-align: center;

    .btn-panel-add {
      margin-bottom: 20px;
    }

    @include media-breakpoint-up(lg) {
      .btn-panel-add {
        float: left;
        margin-bottom: 0;
      }
    }
  }

  &__breadcrumb-back {
    a {
      color: $gray-light;

      .svg-sprite-icon {
        width: 12px;
        height: 18px;
        margin-right: 3px;
        fill: $gray-light;
      }

      &:hover {
        color: $gray-dark;
        text-decoration: none;

        .svg-sprite-icon {
          fill: $gray-dark;
        }
      }
    }
  }
}
