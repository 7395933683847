.indicators {
  $block-name: &;

  @include media-breakpoint-up(lg) {
    text-align: center;
  }

  &__top {
    font-size: 1.125rem;
    letter-spacing: 0.9px;
    color: $gray;

    @include media-breakpoint-up(lg) {
      min-height: 31px;
      margin-bottom: 20px;
    }
  }

  &__togglers {
    margin-bottom: 20px;
  }

  &__score {
    margin-bottom: 0;
    font-size: 1.875rem;
    font-weight: 500;
    letter-spacing: 1.5px;

    small {
      font-size: 1.0625rem;
      font-weight: inherit;
    }
  }

  &__percent {
    @include media-breakpoint-up(lg) {
      text-align: center;
    }
  }

  &__chart {
    min-height: 75px;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      padding: 0 20px;
    }
  }
}
