.reports {

  &__panel {
    overflow-x: scroll;

    @include media-breakpoint-up(md) {
      overflow-x: initial;
    }
  }
}
