.start-list {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: counter;

  li {
    position: relative;
    counter-increment: counter;
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
      width: 160px;
      display: inline-block;
      margin-bottom: 0;
    }

    br {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    &:before {
      position: relative;
      z-index: 1;
      content: counter(counter);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin: 0 auto 10px;
      font-family: PT Sans, sans-serif;
      font-weight: bold;
      text-align: center;
      color: $white;
      background-color: $blue;
      border-radius: 50%;
      box-shadow: 0 0 0 4px $white, 0 0 0 6px $blue;

      @include media-breakpoint-up(md) {
        margin-bottom: 10px;
      }
    }

    &:after {
      @include media-breakpoint-up(md) {
        content: '';
        position: absolute;
        top: 10px;
        right: -90px;
        height: 2px;
        width: 180px;
        background: $gray-lighter;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
