.progress-questions {
  width: 160px;
  height: 8px;
  background-color: $gray-lighter;
  border-radius: 4px;
  font-size: 0;

  &__item {
    display: inline-block;
    height: inherit;
    background: $blue;
    border-radius: inherit;
  }
}
