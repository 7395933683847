.copy-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: $gray-light;
    border-radius: 50%;
    cursor: pointer;
  
    &:hover {
      background-color: $blue;
    }
  
    svg {
      width: 16px;
      height: 16px;
      fill: #fff;
    }
  }
  