.lang {
  padding: 5px 10px;

  a {
    padding: 0 10px;
    border-right: 1px solid #a8b3c2;
    color: #a8b3c2;

    &.is-active {
      font-weight: bold;
      color: $gray-dark;
    }

    &:last-child {
      border-right: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__btn {
    font-weight: bold;
    color: $gray-dark;
    background: transparent;
    border: none;

    // &:hover, &:active {
    //   .icon-menu {
    //     fill: $gray;
    //   }
    // }
  }
}


