.page-layout {
  display: flex;
  flex-grow: 1;
  height: inherit;

  &__container {
    @include make-app-container(20px);
  }

  &__row {
    @include make-row(20px);

    height: 100%;
  }

  &__aside {
    width: 90px;
    flex: 0 0 90px;
    background: #fff;

    @include media-breakpoint-up(lg) {
      .aside {
        position: fixed;
        top: 80px;
        left: 0;
        width: 90px;
        min-height: 100%;
      }
    }
  }

  &__content {
    width: 100%;
    padding: 30px 10px 50px;

    @include media-breakpoint-up(xl) {
      padding: 25px 40px 60px 50px;
      width: calc(100%  - 90px);
    }
  }
}
