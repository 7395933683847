.statistics-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__label, &__update {
    letter-spacing: 0.7px;
    color: $gray;
  }

  &__text {
    font-size: 1rem;
    letter-spacing: 0.75px;
  }

  &__text--question {
    margin-bottom: 0;
    color: $blue;
  }

  &__overal-title {
    margin-bottom: 20px;
    font-size: 1.125rem;
    letter-spacing: 0.9px;
    font-weight: bold;
  }

  &__list {
    margin: 0 0 10px 0;
    padding: 10px 0 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 10px;
      font-size: 14px;
      letter-spacing: 0.75px;

      &:nth-child(5) {
        &:before {
          display: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: $gray-dark;
      }
    }
  }
}
