.filter-collapse {
  margin-bottom: 20px;
  border-radius: 3px;
  box-shadow: 0 11px 21px 0 $gray-lighter;
  border: solid 1px $gray-lighter;
  background-color: $white;

  &__top {
    padding: 20px;
    border-bottom: 1px solid $gray-lighter;
  }

  &__options {
    .select-input {
      margin-bottom: 30px;
    }
  }

  &__switch {
    display: flex;
    align-items: center;
    text-align: right;

    > span {
      display: inline-block;
      vertical-align: middle;
      margin-right: 17px;
      line-height: 70px;
    }

    .page-switch {
      vertical-align: middle;
    }

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  &__bottom {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .btn-cancel {
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        margin-bottom: auto;
        margin-right: 10px;
        margin-left: auto;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      padding-bottom: 25px;
    }
  }
}
