.rate {
  display: flex;
  align-items: flex-end;

  p:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    margin-bottom: 0;
    margin-right: 15px;

    @include media-breakpoint-up(lg) {
      margin-right: 0;
      min-height: 31px;
      margin-bottom: 20px;
    }

    .svg-sprite-icon {
      width: 24px;
      height: 24px;
    }

    .icon-smile {
      fill: $green-dark;
    }

    .icon-meh {
      fill: $gray;
    }

    .icon-sad {
      fill: $color-danger;
    }
  }

  &__score {
    min-width: 80px;
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: $gray-dark;
    line-height: 1.5rem;

    @include media-breakpoint-up(lg) {
      line-height: 2.8125rem;
      text-align: center;
    }
  }

  &__quantity {
    min-width: 60px;
    margin-bottom: 0;
    margin-right: 7px;
    font-size: 17px;
    font-weight: 500;
    color: $gray;
    line-height: 0.8125rem;

    @include media-breakpoint-up(lg) {
      margin-right: 0;
      margin-bottom: 15px;
      line-height: normal;
      text-align: center;
    }

    small {
      font-size: 0.8125rem;
      font-weight: inherit;
    }
  }

  &__percent {
    min-width: 30px;
    margin-bottom: 0;
    margin-right: 7px;
    line-height: 0.625rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 1rem;
      margin-right: 0;
      line-height: normal;
    }
  }

  &__responses {
    margin-left: auto;

    @include media-breakpoint-up(md) {
      margin-left: initial;
    }
  }
}
