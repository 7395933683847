.page-form {
  position: relative;
  border-radius: 3px;
  border: solid 1px $gray-lighter;
  background-color: $white;


  &__container {
    padding: 15px 20px 30px 20px;

    @include media-breakpoint-up(md) {
      padding: 25px 28% 30px 30px;
    }

    @include media-breakpoint-up(lg) {
      padding: 35px 38% 30px 70px;
    }
  }

  .form-group {
    position: relative;

    .label-number {
      position: absolute;
      top: 0;
      left: -51px;
      display: none;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      text-align: center;
      font-weight: bold;
      line-height: 25px;
      color: $white;
      background-color: $blue;
      border-radius: 50%;
      font-family: PT Sans, sans-serif;

      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }

    &.has-label-line {

      &:before {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        left: -40px;
        display: none;
        width: 2px;
        background-color: $blue;

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }
  }

  .form-control {

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  select {
    position: relative;
    webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../../../img/chevron.svg) 99% / 100% no-repeat;
    background-size: 30px;
  }
}
