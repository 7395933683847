.main-page {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #eefaff;

  @include media-breakpoint-up(xl) {
    min-height: 780px;
    flex-direction: row;
  }

  .container {
    @include media-breakpoint-up(xl) {
      max-width: 1240px;
    }
  }

  .carousel {
    flex-grow: 1;
  }

  .carousel-inner {
    height: 100%;
    padding-bottom: 40px;

    @include media-breakpoint-up(xl) {
      padding-bottom: 0;
    }
  }

  .carousel-item {
    height: 100%;

    @include media-breakpoint-up(xl) {
      display: flex;
      align-items: center;
      justify-content: center;

      &.is-happy {
        background: url(../../../img/slide-1.jpg) no-repeat center 40% ;
      }

      &.is-indifferent {
        background: url(../../../img/slide-2.jpg) no-repeat center 40% ;
      }

      &.is-angry {
        background: url(../../../img/slide-3.jpg) no-repeat center 40%;
      }
    }
  }

  &__logo {

    @include media-breakpoint-up(xl) {
      position: absolute;
      z-index: 2;
      left: 0;
      width: 100%;
    }

    .container {
      padding-top: 40px;
      padding-bottom: 40px;
      text-align: center;

      @include media-breakpoint-up(xl) {
        text-align: left;
      }
    }
  }
}

.main-page-footer {
  font-family: 'Montserrat', sans-serif;
  background-color: #ffffff;
  color: #485766;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    @include media-breakpoint-up(xl) {
      max-width: 1240px;
      padding-top: 25px;
      padding-bottom: 25px;
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-start;
    }
  }

  p {

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
      margin-right: 80px;
      font-size: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.has-logo {
      position: relative;
      padding-left: 25px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url(../../../img/logo-sm.png) no-repeat center;
        background-size: contain;
      }
    }

    a {
      color: #485766;

      &:hover, &:focus, &:active {
        text-decoration: none;
        color: #485766;
      }
    }
  }
}

.main-slide {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: #040830;

  @include media-breakpoint-up(xl) {
    text-align: left;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;

    @include media-breakpoint-up(xl) {
      margin-bottom: 30px;
      font-size: 24px;
    }

    span {
      display: block;
      margin-top: 10px;
      font-weight: 900;
      font-size: 45px;
      text-transform: uppercase;

      @include media-breakpoint-up(xl) {
        font-size: 60px;
        margin-top: 30px;
      }
    }
  }

  &__text {
    margin-bottom: 20px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 60px;
      font-size: 22px;
    }
  }

  &__btn {

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }

    .btn {
      min-width: 250px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.9px;
      color: #050931;
      border: solid 2px #f3cb25;

      &:hover {
        background-color: #f3cb25;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  &__btn:first-child {
    @include media-breakpoint-up(xl) {
      margin-right: 15px;
    }
  }

  &__btn:last-child {
    .btn {
      border: solid 2px #d6d4d0;
      
      &:hover {
        background-color: #d6d4d0;
      }
    }
  }

  &__img-xs {
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
}

.main-slide-btn-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }
}
