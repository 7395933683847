.date-dropdown {
  width: 40px;
  height: 40px;

  @include media-breakpoint-up(md) {
    width: 30px;
    height: 30px;
  }

  .dropdown-menu {
    width: 190px;
    box-shadow: 0 30px 40px 0 $gray-light;
    border: solid 1px $gray-lighter;
    border-radius: 3px;
    font-size: 14px;
    letter-spacing: 0.7px;
  }

  .dropdown-menu-right {
    top: 38px;

    &:before {
      content: '';
      position: absolute;
      top: -7px;
      right: 8px;
      width: 12px;
      height: 12px;
      z-index: -1;
      transform: rotate(-45deg);
      border-top: solid 1px $gray-lighter;
      border-right: solid 1px $gray-lighter;
      background: $white;
    }

    @media(max-width: 730px) {
      left: 0;
    }
  }

  .dropdown-item {
    margin-bottom: 10px;
    padding-top: 0;
    padding-bottom: 0;
    color: $gray;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.is-active {
      background-color: $blue;
      color: $white;
    }

    &:hover, &:focus, &:active {
      background-color: $blue;
      color: $white;
    }
  }
}
