.indicators-percent {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 500;

  @include media-breakpoint-up(lg) {
    justify-content: center;
  }

  .svg-sprite-icon {
    margin-right: 3px;
    width: 9px;
    height: 14px;

    @include media-breakpoint-up(lg) {
      margin-right: 0;
    }
  }

  &.is-up {
    color: $color-success;

    .svg-sprite-icon {
      fill: $color-success;
    }
  }

  &.is-down {
    color: $color-danger;

    .svg-sprite-icon {
      transform: rotate(180deg);
      fill:  $color-danger;
    }
  }
  &.not-visible {
    visibility: hidden;
  }
}