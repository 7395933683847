.reviews__panels {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.single-review {
  box-sizing: border-box;
  width: 80%;
}

.review-card {
  border-radius: 20px;
  margin-bottom: 0 !important;
  box-shadow: 0 11px 21px 0 $gray-lighter;

  .circle {
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50%;
  }

  .google-logo {
    width: 16px;
    height: auto;
  }

  .google-logo-modal {
    width: 50px;
    height: auto;
    margin: 0 5px;
  }

  .bi-star,
  .bi-star-fill {
    font-size: 20px;
    margin-right: 4px;
  }

  .text-warning {
    color: #ffc107 !important;
  }

  download-section {
    font-size: 16px;
    color: #6c757d;
    cursor: pointer;

    .bi-download {
      font-size: 18px;
    }

    &:hover {
      color: #5a6268;
      text-decoration: underline;
    }

    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }
}

.review-modal-header {
  color: $blue;
  font-size: 1.2rem;
  font-weight: 500;
}

.divider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f8f9fa;
  padding: 20px;

  .content-section {
    display: flex;
    width: 100%;
    max-width: 800px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    overflow: hidden;

    .left-section,
    .right-section {
      padding: 20px;
    }

    .left-section {
      background-color: #ffffff;
      border-right: 1px solid #ced4da;
      width: 340px;
    }

    @media (max-width: 999px) {
      .left-section {
        width: 100%;
      }
    }

    .right-section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: calc(100% - 340px);
      overflow: hidden;
      position: relative;
    }

    @media (max-width: 999px) {
      .right-section {
        width: 100%;
      }
    }
  }

  @media (max-width: 999px) {
    .content-section {
      flex-direction: column;
    }
  }
}

.review-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  max-height: calc(100% - 40px);
}

.review-card-modal {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-width: 100%;
  }
}

.canvas-settings {
  background-color: #f8f9fa;
  border: 1px solid #ddd;

  border-radius: 8px;
  max-width: 500px;
  margin: auto;

  .setting-line {
    margin-bottom: 1rem;

    .label-text {
      font-size: 14px;
      font-weight: 600;
      color: #6c757d;
      margin-bottom: 0.5rem;
    }

    .slider-value {
      font-size: 12px;
      color: #343a40;
      text-align: right;
      margin-top: 5px;
    }

    .color-circles {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 5px;

      .color-circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #ddd;
        cursor: pointer;
        transition: transform 0.2s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  @media (max-width: 576px) {
    .setting-line {
      .color-circles {
        flex-wrap: wrap;
        gap: 5px;

        .color-circle {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

.custom-color-container {
  width: 50%;
}

.download-review-button {
  color: #6c757d;
}
