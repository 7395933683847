.logo-customer {
  display: flex;
  align-items: center;
  height: 100% ;

  &__text {
    display: block;
    min-width: 180px;
    max-width: 360px;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid $gray-lighter;
    background-color: $gray-lightest;
    border-radius: 20px;

    @include text-truncate();
  }
}
