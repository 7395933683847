.aside {
  width: 100%;
  background-color: $white;


  &__container {
    padding-top: 105px;
    padding-bottom: 90px;
  }
}
