.comment {
  padding: 15px;
  font-size: 16px;
  border-radius: 3px;
  background-color: #f1f8ff;

  @include media-breakpoint-up(md) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__head {
    margin-bottom: 5px;

    > span {
      display: block;
      letter-spacing: 0.8px;
      text-align: left;
      color: $gray;

      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-right: 10px;
      }

      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-radius: 50%;
        background: $blue;

        &.has-phone {
          background: $green-dark;
        }

        svg {
          width: 10px;
          height: 10px;
          fill: $white
        }
      }
    }

    > a {
      display: inline-block;
      margin-top: 5px;
      padding: 0 12px;
      font-weight: bold;
      letter-spacing: 0.8px;
      text-align: center;
      color: $white;
      line-height: 20px;
      border-radius: 10px;
      background-color: $green-dark;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }

      &:hover, &:active, &:focus {
        text-decoration: none;
        color: $white;
        background-color: $green-dark;
      }
    }
  }

  &__text {
    line-height: normal;
    overflow-wrap: anywhere;

    @include media-breakpoint-up(md) {
      max-height: initial;
      padding-left: 30px;
    }
  }

  &__collapse-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin: 10px auto 0;
    padding: 0;
    border: none;
    background: transparent;

    @include media-breakpoint-up(md) {
      display: none;
    }

    &:focus {
      outline: none;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: $body-color;
    }
  }
}
