.page-search {

  .form-group {
    position: relative;
  }

  .form-control {
    height: 30px;
    padding-left: 34px;
    background: $white url(../../../img/search.svg) no-repeat 10px center;

    &::placeholder {
      color: $gray;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $gray;
    }

    &::-ms-input-placeholder {
      color: $gray;
    }
  }
}