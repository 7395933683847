.user-avatar {
  display: block;
  width: 40px;
  height: 40px;
  overflow: hidden;
  font-weight: bold;
  letter-spacing: 1.4px;
  line-height: 40px;
  text-align: center;
  color: $gray-light;
  border: 1px solid $border-color;
  background-color: $gray-lightest;
  border-radius: 50%;
}
