.comments {
  width: 100%;

  &__head {
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .sortable-link {
      display: inline-block;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: $blue;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &__list {
    margin-bottom: 20px;

    .comment + .comment {
      margin-top: 10px;
    }
  }
}
